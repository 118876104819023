// extracted by mini-css-extract-plugin
export var backdrop = "iddt-page-module--backdrop--c60d6";
export var closeButton = "iddt-page-module--closeButton--cdbcc";
export var iddtLogo = "iddt-page-module--iddtLogo--bf428";
export var logosContainer = "iddt-page-module--logosContainer--bd6b8";
export var memberInfo = "iddt-page-module--memberInfo--366a6";
export var modal = "iddt-page-module--modal--17565";
export var modalContent = "iddt-page-module--modalContent--906f2";
export var modalDesc = "iddt-page-module--modalDesc--525e3";
export var modalExample = "iddt-page-module--modalExample--f4dbf";
export var modalFooter = "iddt-page-module--modalFooter--98ebc";
export var modalHeader = "iddt-page-module--modalHeader--d3fb8";
export var modalTitle = "iddt-page-module--modalTitle--503c6";
export var primaryButton = "iddt-page-module--primaryButton--235e3";
export var readMoreButton = "iddt-page-module--readMoreButton--e3f30";
export var readMoreIcon = "iddt-page-module--readMoreIcon--1e7e6";
export var secondaryButton = "iddt-page-module--secondaryButton--47d38";
export var teamHeader = "iddt-page-module--teamHeader--0e79d";
export var teamMember = "iddt-page-module--teamMember--f0cc8";
export var teamMemberContainer = "iddt-page-module--teamMemberContainer--3fb6f";
export var teamTitle = "iddt-page-module--teamTitle--64507";