import React from "react";
import { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Showcase from "../components/Showcase";
import { Modal } from "react-overlays";
import InfoBlock from "../components/InfoBlock";
import Footer from '../components/Layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


import * as styles from '../styles/pages/iddt-page.module.scss';


export const Head = () => (
  <>
    <title>IDDT | GROWoneAFRICA&trade;</title>
    <meta name="description" content="Industrial Dacha Development Team. A passionate team dedicated to spearheading innovation and cutting-edge technologies in the realm of hemp processing in South Africa" />
  </>
)

const HomePage = ({ data }) => { //was IddtPage
  const [ showModal, setShowModal ] = useState(false)
  const [ modalData, setModalData ] = useState()

  const images = data.localImages.edges.reduce((acc, edge) => {
    acc[edge.node.name] = edge.node.childImageSharp.gatsbyImageData
    return acc
  }, {})

  const {
    bannerImage,
    aboutUsSection,
    visionSection,
    missionSection,
    dachaMattersSection,
    ourJouneySection,
  } = data.sanityIddtPage

  const teamMembers = data.allSanityTeam;
  const brands = data.allSanityIddtBrands;

  const handleClose = () => setShowModal(false);

  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  return (
    <>
      <main>
      <Showcase image={bannerImage.asset} mainText={data.sanityIddtPage.mainText} subText={data.sanityIddtPage.subText} textVisible={data.sanityIddtPage.bannerTextVisible}/> {/* data.showcaseImage */}

        {aboutUsSection.display && (
          <InfoBlock
            featuredImage={<GatsbyImage image={aboutUsSection.featuredImage.asset.gatsbyImageData} alt="About Us" />}
            id="next"
          >
            <h2>About Us</h2>
            <p>{aboutUsSection._rawContent[0].children[0].text}</p>
          </InfoBlock>
        )}
        {visionSection.display && (
          <InfoBlock
            featuredImage={<GatsbyImage image={visionSection.featuredImage.asset.gatsbyImageData} alt="Vision" />}
            reversed
          >
            <h2>Our Vision:</h2>
            <p>{visionSection._rawContent[0].children[0].text}</p>
          </InfoBlock>
        )}
        {missionSection.display && (
          <InfoBlock
            featuredImage={<GatsbyImage image={missionSection.featuredImage.asset.gatsbyImageData} alt="Mission" />}
          >
            <h2>The Mission:</h2>
            {missionSection._rawContent.map((content, index) => (
              <p key={index}>{content.children[0].text}</p>
            ))}
          </InfoBlock>
        )}
        {dachaMattersSection.display && (
          <InfoBlock
            featuredImage={<GatsbyImage image={dachaMattersSection.featuredImage.asset.gatsbyImageData} alt="Why DACHA Matters" />}
            reversed
          >
            <h2>Why DACHA Matters</h2>
            {dachaMattersSection._rawContent.map((content, index) => (
              <p key={index}>{content.children[0].text}</p>
            ))}
          </InfoBlock>
        )}
        {ourJouneySection.display && (
          <InfoBlock
            featuredImage={<GatsbyImage image={ourJouneySection.featuredImage.asset.gatsbyImageData} alt="Join Our Journey" />}
          >
            <h2>Join Our Journey</h2>
            <p>{ourJouneySection._rawContent[0].children[0].text}</p>
          </InfoBlock>
        )}

      <h2 className={styles.teamHeader}>Meet the Industrial Dacha Development Team</h2>
      <p style={{ marginTop: '5rem', marginBottom: '20px', width: '80%', margin: '0 auto', display: 'block', paddingTop: '2rem', paddingBottom: '2rem', textAlign: 'center' }}>Our diverse and talented team are driven by a shared passion for the dacha industry. Together, we arecommitted to exploring new possibilities, driving innovation, and making a positive impact within ourown communities and the greater cannabis community at large. Each member’s unique expertise playsa role in creating a team that is able to face each project with confidence and excitement in growing anddeveloping a thriving dacha community.</p>


      <div className={styles.teamMemberContainer}>
        {teamMembers.nodes.map((teamMember) => (
          <div key={teamMember._id} className={styles.teamMember}>
            <GatsbyImage
              image={teamMember.image.asset.gatsbyImageData}
              alt={teamMember.title}
              imgStyle={{ objectFit: 'cover', width: '20rem', height: '20rem' }}
              style={{ width: '20rem', height: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            />
            <h2 className={styles.teamTitle}>{teamMember.title}</h2>
            <button className={styles.readMoreButton} onClick={() => {setShowModal(true); setModalData(teamMember)}}>
              <span>Read more</span>
              <FontAwesomeIcon className={styles.readMoreIcon} icon={faArrowRight} />
            </button>
          </div>
        ))}
      </div>

      {modalData && 
        <Modal
          className={styles.modal}
          show={showModal}
          onHide={handleClose}
          renderBackdrop={renderBackdrop}
        >
          <div className={styles.modalContent} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <div>
              <div className={styles.modalHeader}>
                {/* <div className={styles.modalTitle}>{modalData.title}</div> */}
                <div>
                  <span className={styles.closeButton} onClick={handleClose}>x</span>
                </div>
              </div>

              <div className={styles.modalDesc}>
                <div className={styles.memberInfo}>
                  <div>
                    <div className={styles.modalTitle}>{modalData.title}</div>
                    <ul style={{ margin: '1rem 0 0 1rem'}}>
                      {modalData.description.map((info, infoIndex) => (
                          <li key={infoIndex} style={{ margin: '1rem 0'}}>{info}</li>
                      ))}
                    </ul>
                    </div>
                    <GatsbyImage
                      image={modalData.image.asset.gatsbyImageData}
                      alt={modalData.title}
                      imgStyle={{ objectFit: 'contain', width: '15rem', height: '20rem' }}
                      style={{ width: '15rem', height: '15rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '1rem' }}
                    /> 
                </div>
              </div>
              
              <div className={styles.sponsorSection}>
                {modalData.sponsorImage.map((image) => (
                    <GatsbyImage
                    image={image.asset.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain', width: '7rem', height: '7rem' }}
                    style={{ width: '7rem', height: '7rem', marginRight: '1rem'}}
                  /> 
                ))}

                <div className={styles.modalFooter}>
                  <button className={styles.secondaryButton} onClick={handleClose}>
                    Close
                  </button>
                </div>
              </div>            
            </div>
          </div>
        </Modal>
      }

        {/* Supporting Logos */}
      <div className={styles.logosContainer}>
        {brands.nodes.map((brand) => (
          <div key={brand.name} className="brand">
            <a href={brand.url} target="_blank" rel="noopener noreferrer">
              <GatsbyImage
                image={brand.logo.asset.gatsbyImageData}
                alt={brand.name}
                imgStyle={{ objectFit: 'contain', width: '100%', height: '100%' }}
                style={{ width: '100%', height: '100%' }}
              />
            </a>
          </div>
        ))}
      </div>
      </main>
      <Footer>
      </Footer>
    </>
  )
}

export const query = graphql`
  query IddtPageQuery {

    localImages: allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "iddt-page" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    allSanityTeam {
      nodes {
        _id
        title
        description
        image {
          asset {
            gatsbyImageData
          }
        }
        sponsorImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }

    allSanityIddtBrands {
      nodes {
        name
        url
        logo {
          asset {
            gatsbyImageData
          }
        }
      }
    }

    sanityCompanyInfo {
      socialMediaLinks {
        youtube
      }
    }

    sanityIddtPage {
      bannerImage {
        asset {
          gatsbyImageData
        }
      }
      mainText
      subText
      bannerTextVisible

      aboutUsSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        display
        _rawContent
      }
      visionSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        display
        _rawContent
      }
      missionSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        display
        _rawContent
      }
      dachaMattersSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        display
        _rawContent
      }
      ourJouneySection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        display
        _rawContent
      }
    }
  
  }
`

export default HomePage //was Iddtpage